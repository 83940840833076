import cn from 'classnames';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';

import type { FilterItem } from '@shared/api';
import type { Locale } from '@shared/libs';
import { Radio } from '@shared/ui';

import styles from '../../../listing.module.css';
import stylesList from './list.module.css';

type Props = {
  data: FilterItem['VALUES'];
  name?: string;
  locale: Locale;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
};

export const RadioList = ({ data, locale, value, name, setValue }: Props) => {
  const sortedData = useMemo(() => {
    const arr = Object.values(data);
    arr.sort((a, b) => (a < b ? 1 : -1));
    return arr;
  }, [data]);

  const [showAll, setShowAll] = useState(false);

  const visibleData = useMemo(() => {
    if (sortedData.length <= 3 || showAll) {
      return sortedData;
    }

    return [...sortedData].slice(0, 3);
  }, [sortedData, showAll]);

  return (
    <div className={styles.list}>
      {visibleData.map(({ VALUE, CONTROL_NAME, ELEMENT_COUNT }) => (
        <div key={CONTROL_NAME} className={styles.filterOption}>
          <Radio
            id={CONTROL_NAME}
            name={name || 'radio'}
            onChange={() => {
              setValue(CONTROL_NAME);
            }}
            value={value}
            checked={value === CONTROL_NAME}
            disabled={Number(ELEMENT_COUNT) === 0}
          >
            <span className={stylesList.item}>
              {VALUE}
              {Number(ELEMENT_COUNT) > 0 && (
                <span className={stylesList.count}>
                  {new Intl.NumberFormat('ru-RU').format(Number(ELEMENT_COUNT))}
                </span>
              )}
            </span>
          </Radio>
        </div>
      ))}
      {sortedData.length > 3 && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <p
          className={cn(stylesList.more, {
            [stylesList.open]: showAll,
          })}
          onClick={() => {
            setShowAll((prev) => !prev);
          }}
        >
          {showAll ? locale.MENU_TOGGLE_CLOSE : locale.MENU_TOGGLE_OPEN}
        </p>
      )}
    </div>
  );
};
