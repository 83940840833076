'use client';

import { useRouter, usePathname } from 'next/navigation';
import { useContext, useState, useCallback, ReactNode } from 'react';

import type { FilterResponse } from '@shared/api';
import type { Locale } from '@shared/libs';
import { ModalContext } from '@shared/libs';
import type { Notes } from '@shared/types';
import { Select, Loader, Button, ButtonColor } from '@shared/ui';
import { Filter, FilterMobile, Result } from './components';
import styles from './listing.module.css';

type TProps = {
  topSlot?: ReactNode;
  locale: Locale;
  page?: number;
  smart_url?: string;
  filterData: FilterResponse;
  SORT?: string;
  data: {
    data: Notes[];
    pager: {
      page_current: number;
      pages_all: number;
      records_all: number;
      records_per_page: number;
    };
  };
  yaCategory?: string;
  h1?: string;
};

// https://www.vseinstrumenti.ru/category/kompressory-392/
export const ListingClient = ({
  locale,
  filterData,
  data,
  page,
  smart_url,
  topSlot,
  SORT = '',
  yaCategory,
  h1,
}: TProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const openModal = useContext(ModalContext);
  const pathname = usePathname();
  const router = useRouter();

  const onChangeOrder = (val: string) => {
    router.push(val ? `${pathname}?SORT=${val}` : pathname);
  };

  const ComponentFilterMobile = useCallback(
    // eslint-disable-next-line react/no-unused-prop-types
    ({ onClose }: { onClose?: () => void }) => (
      <FilterMobile
        locale={locale}
        filterData={filterData}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        onClose={onClose}
      />
    ),
    [filterData, isLoading, locale],
  );

  //

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Filter
          locale={locale}
          filterData={filterData}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
        <div className={styles.listWrapper}>
          {/* <div className={styles.history}> */}
          {/*  /!* <PageHistory /> *!/ */}
          {/*  Каталог / Пианино */}
          {/* </div> */}
          {/* <div className={styles.tabs}></div> */}
          {/* <div> */}
          {/*  <H2 className={styles.h2}> */}
          {/*    {locale.SEARCH_PIANO}{' '} */}
          {/*    <span className={cn(styles.recordsNumber, unbounded.className)}> */}
          {/*      {getSpacedNum(data?.pager?.records_all)} */}
          {/*    </span> */}
          {/*  </H2> */}
          {/* </div> */}
          <div className={styles.commonFilters}>
            {topSlot}
            {/* <div className={styles.tabsContainer}>
                {tabsFilters.map((f) => (
                  <Tab key={f?.title} filled={f?.checked}>
                    <Tab.Title>{f?.title}</Tab.Title>
                    <Tab.Amount>{f?.amount}</Tab.Amount>
                  </Tab>
                ))}
              </div> */}
            <Button
              color={ButtonColor.BLACK}
              className={styles.openMobile}
              onClick={() => {
                openModal({
                  modal: {
                    title: locale.LISTING_FILTERS || 'LISTING_FILTERS',
                    light: true,
                    fullSize: true,
                    Content: ComponentFilterMobile,
                  },
                });
              }}
            />
            <div className={styles.sort}>
              <Select
                options={[
                  { label: locale.SORT_POPULARITY || '', value: '' },
                  { label: locale.SORT_NEW || '', value: 'ID,DESC' },
                  { label: locale.SORT_OLD || '', value: 'ID,ASC' },
                ]}
                onChange={onChangeOrder}
                defaultValue={SORT}
              />
            </div>
          </div>
          {isLoading && (
            <div className={styles.wrapLoading}>
              <Loader />
            </div>
          )}
          <Result
            data={data}
            locale={locale}
            page={page}
            smart_url={smart_url}
            SORT={SORT}
            h1={h1}
            yaCategory={yaCategory}
          />
        </div>
      </div>
    </div>
  );
};
