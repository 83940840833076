import cn from 'classnames';
import he from 'he';
import Link from 'next/link';
import type { ReactNode } from 'react';

import { Locale } from '@shared/libs';
import { Complexity } from '@shared/ui';

import styles from '../../card.module.css';
import { Preview, Instruments, stylesMusicians } from '../../components';
import stylesFavoriteNote from './favorite-note.module.css';

interface Props {
  id: number;
  title: string;
  url: string;
  arrangement: {
    code: string;
    title: string;
  };
  img: {
    src: string;
    src_256_362: string;
  };
  complexity: {
    level: 10 | 20 | 30;
    title: string;
  } | null;
  musicians: {
    title: string;
  }[];
  RemoveSlot: (props: { id: number; locale: Locale }) => ReactNode;
  RemoveIcon: (props: { id: number }) => ReactNode;
  locale: Locale;
}

export const FavoriteNote = ({
  id,
  url,
  img,
  title,
  musicians,
  arrangement,
  complexity,
  RemoveSlot,
  RemoveIcon,
  locale,
}: Props) => {
  return (
    <div className={cn(styles.cardHorisontal, styles.cardWhite)}>
      <div className={stylesFavoriteNote.removeMobile}>
        <RemoveIcon id={id} />
      </div>
      <Complexity complexity={complexity} className={styles.complexityAbsolute} />
      <Preview href={url} target="_blank" src={img.src_256_362 || img.src} alt={title} />

      <div className={styles.contentHorisontal}>
        <div className={styles.contentTop}>
          <div className={stylesMusicians.musicians}>
            <Link href={url} target="_blank" prefetch={false}>
              {musicians.map((musician) => musician.title).join(', ')}
            </Link>
          </div>
          <div>
            <Link href={url} className={styles.title} target="_blank" prefetch={false}>
              {he.decode(title)}
            </Link>
          </div>
          <Instruments instruments={[arrangement]} />
        </div>
        <div className={stylesFavoriteNote.contentBottom}>
          <div className={stylesFavoriteNote.removeDesktop}>
            <RemoveSlot id={id} locale={locale} />
          </div>
          <p className={styles.variants}>.pdf , .midi</p>
        </div>
      </div>
    </div>
  );
};
