import cn from 'classnames';
import { useRouter, usePathname } from 'next/navigation';
import { useLayoutEffect, useState, useMemo, useRef } from 'react';

import { useSmartSearchMutattion } from '@shared/api';
import type { FilterResponse } from '@shared/api';
import { ENV } from '@shared/config';
import type { Locale } from '@shared/libs';
import { H4, Toggle, Button, ButtonColor } from '@shared/ui';

import styles from '../../listing.module.css';
import { CheckboxList } from './components';

type TProps = {
  locale: Locale;
  filterData: FilterResponse;
  setIsLoading: (value: boolean) => void;
  isLoading: boolean;
};

export const Filter = ({
  locale,
  filterData: initialFilterData,
  setIsLoading,
  isLoading,
}: TProps) => {
  const [lastChangeElement, setLastChangeElement] = useState<HTMLInputElement | null>(null);
  const [pathLink, setPathLink] = useState<undefined | string>(undefined);
  // Для стейта (все выбранные чекбоксы при инициализации страницы)
  const smartSearchMutattion = useSmartSearchMutattion();

  const musicianKey = useMemo(() => {
    const musician = initialFilterData.data.ITEMS[ENV.FILTER_ID_MUSICIAN as 189]?.VALUES;
    return musician && Object.values(musician)[0]?.CONTROL_NAME;
  }, [initialFilterData]);

  const initialCheckboxData = useMemo(() => {
    const result: string[] = [];
    Object.entries(initialFilterData.data.ITEMS).forEach(([id, { VALUES }]) => {
      if (Number(id) !== ENV.FILTER_ID_IS_FREE) {
        Object.values(VALUES).forEach(({ CHECKED, CONTROL_NAME, DISABLED }) => {
          if (CHECKED && !DISABLED) {
            result.push(CONTROL_NAME);
          }
        });
      }
    });

    return result;
  }, [initialFilterData]);

  const [checkboxData, setCheckboxData] = useState<string[]>(initialCheckboxData);
  const [filterData, setFilterData] = useState<FilterResponse>(initialFilterData);

  const initialMusician = useMemo(() => {
    const musician = initialFilterData?.data.ITEMS[ENV.FILTER_ID_MUSICIAN as 189];
    if (!musician || Array.isArray(musician)) {
      return undefined;
    }

    const valueKey = Object.keys(musician.VALUES)[0];

    if (valueKey === undefined) {
      return undefined;
    }
    musician.VALUES[valueKey].CHECKED = checkboxData.includes(
      musician.VALUES[valueKey].CONTROL_NAME,
    );

    return musician;
  }, [initialFilterData, checkboxData]);

  // отдельно обрабатывается сложность - т.к. это toggle
  const [isFree, setIsFree] = useState(
    initialFilterData.data.ITEMS[ENV.FILTER_ID_IS_FREE as 63].VALUES[ENV.FILTER_IS_FREE_VALUE]
      ?.CHECKED || false,
  );
  const router = useRouter();
  const pathname = usePathname();

  const firstRender = useRef(true);

  useLayoutEffect(() => {
    if (smartSearchMutattion.data) {
      // @ts-expect-error type
      setFilterData(smartSearchMutattion.data);
    }
  }, [smartSearchMutattion.data]);

  useLayoutEffect(() => {
    if (smartSearchMutattion.data) {
      const response = smartSearchMutattion.data as FilterResponse;

      firstRender.current = true;
      const path = response.data.SEF_SET_FILTER_URL;
      setCheckboxData(() => {
        const result: string[] = [];
        Object.entries(response.data.ITEMS).forEach(([id, { VALUES }]) => {
          if (Number(id) !== ENV.FILTER_ID_IS_FREE) {
            Object.values(VALUES).forEach(({ CHECKED, CONTROL_NAME }) => {
              if (CHECKED) {
                result.push(CONTROL_NAME);
              }
            });
          }
        });

        return result;
      });

      firstRender.current = true;

      firstRender.current = true;
      setIsFree(
        response.data.ITEMS[ENV.FILTER_ID_IS_FREE as 63].VALUES[ENV.FILTER_IS_FREE_VALUE]
          ?.CHECKED || false,
      );
      setPathLink(path);
    }
  }, [smartSearchMutattion.data, router]);

  const { mutate } = smartSearchMutattion;

  const freeControlName =
    initialFilterData?.data?.ITEMS[ENV.FILTER_ID_IS_FREE as 63].VALUES[ENV.FILTER_IS_FREE_VALUE]
      ?.CONTROL_NAME;

  useLayoutEffect(
    () => () => {
      firstRender.current = true;
    },
    [],
  );
  useLayoutEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      let filters: string[] = [];

      if (checkboxData.length) {
        filters = [...checkboxData];
      }

      if (isFree && freeControlName) {
        filters.push(freeControlName);
      }

      mutate({ filters });
    }
  }, [checkboxData, isFree, mutate, musicianKey, freeControlName]);

  useLayoutEffect(() => {
    const timeoutId = setTimeout(() => {
      if (smartSearchMutattion.data) {
        setLastChangeElement(null);
      }
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [smartSearchMutattion.data, lastChangeElement]);

  const showActions =
    pathLink &&
    pathLink !== pathname &&
    !(pathLink === '/category/clear/apply/' && pathname === '/');

  return (
    <div
      className={cn(styles.filters, {
        [styles.isLoading]: smartSearchMutattion.isLoading || isLoading,
      })}
    >
      {lastChangeElement && !smartSearchMutattion.isLoading && (
        <div
          className={styles.rightActionBlock}
          style={{
            top: `${lastChangeElement.offsetTop}px`,
          }}
        >
          <Button
            onClick={() => {
              setLastChangeElement(null);
              setIsLoading(true);
              window?.scrollTo(0, 0);
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
              router.push(pathLink!);
              setPathLink(undefined);
            }}
          >
            {locale.LISTING_SEARCH}
            {smartSearchMutattion.data?.data.ELEMENT_COUNT
              ? ` (${smartSearchMutattion.data?.data.ELEMENT_COUNT})`
              : ''}
          </Button>
        </div>
      )}
      <div className={styles.filtersBlock}>
        <div className={styles.filtersBlockOneLineTitle}>
          <H4 unboundedFont={false} className={styles.textToggle}>
            {filterData?.data.ITEMS[ENV.FILTER_ID_IS_FREE as 63].NAME}
          </H4>
          <Toggle
            toggleName={freeControlName}
            checked={isFree}
            onClick={(e) => {
              setLastChangeElement(null);
              setTimeout(() => {
                setLastChangeElement(e.target);
              }, 200);
              setIsFree((prev) => !prev);
            }}
            disabled={
              Number(
                filterData?.data.ITEMS[ENV.FILTER_ID_IS_FREE as 63].VALUES[ENV.FILTER_IS_FREE_VALUE]
                  .ELEMENT_COUNT,
              ) === 0
            }
          />
        </div>
      </div>
      {initialMusician && (
        <div className={styles.filtersBlock}>
          <H4 unboundedFont={false} className={styles.filtersBlockTitle}>
            {initialMusician.NAME}
          </H4>
          <CheckboxList
            id="FILTER_ID_MUSICIAN"
            data={initialMusician.VALUES || {}}
            locale={locale}
            checkboxData={checkboxData}
            setCheckboxData={setCheckboxData}
            setLastChangeElement={setLastChangeElement}
          />
        </div>
      )}

      <div className={styles.filtersBlock}>
        <H4 unboundedFont={false} className={styles.filtersBlockTitle}>
          {filterData?.data.ITEMS[ENV.FILTER_ID_INSTRUMENTS as 176].NAME}
        </H4>
        <CheckboxList
          id="FILTER_ID_INSTRUMENTS"
          data={filterData?.data.ITEMS[ENV.FILTER_ID_INSTRUMENTS as 176].VALUES || {}}
          locale={locale}
          checkboxData={checkboxData}
          setCheckboxData={setCheckboxData}
          setLastChangeElement={setLastChangeElement}
        />
      </div>
      <div className={styles.filtersBlock}>
        <H4 unboundedFont={false} className={styles.filtersBlockTitle}>
          {filterData?.data.ITEMS[ENV.FILTER_ID_COMPLEXITY as 183].NAME}
        </H4>
        <CheckboxList
          id="FILTER_ID_COMPLEXITY"
          data={filterData?.data.ITEMS[ENV.FILTER_ID_COMPLEXITY as 183].VALUES || {}}
          locale={locale}
          checkboxData={checkboxData}
          setCheckboxData={setCheckboxData}
          setLastChangeElement={setLastChangeElement}
        />
      </div>
      <div className={styles.filtersBlock}>
        <H4 unboundedFont={false} className={styles.filtersBlockTitle}>
          {filterData?.data.ITEMS[ENV.FILTER_ID_ARRANGEMENT as 50].NAME}
        </H4>
        <CheckboxList
          id="FILTER_ID_ARRANGEMENT"
          data={filterData?.data.ITEMS[ENV.FILTER_ID_ARRANGEMENT as 50].VALUES || {}}
          locale={locale}
          checkboxData={checkboxData}
          setCheckboxData={setCheckboxData}
          setLastChangeElement={setLastChangeElement}
        />
      </div>
      <div className={styles.filtersBlock}>
        <H4 unboundedFont={false} className={styles.filtersBlockTitle}>
          {filterData?.data.ITEMS[ENV.FILTER_ID_GENRE as 61].NAME}
        </H4>
        <CheckboxList
          id="FILTER_ID_GENRE"
          data={filterData?.data.ITEMS[ENV.FILTER_ID_GENRE as 61].VALUES || {}}
          locale={locale}
          checkboxData={checkboxData}
          setCheckboxData={setCheckboxData}
          setLastChangeElement={setLastChangeElement}
        />
      </div>

      {(showActions ||
        (pathname !== '/' && pathname !== initialFilterData.data.SEF_DEL_FILTER_URL)) && (
        <div className={cn(styles.filtersBlock, styles.actionBlock)}>
          {showActions && (
            <Button
              onClick={() => {
                setLastChangeElement(null);
                setIsLoading(true);
                window?.scrollTo(0, 0);
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                router.push(pathLink!);
                setPathLink(undefined);
              }}
            >
              {locale.LISTING_SEARCH}
              {smartSearchMutattion.data?.data.ELEMENT_COUNT
                ? ` (${Number(smartSearchMutattion.data?.data.ELEMENT_COUNT).toLocaleString()})`
                : ''}
            </Button>
          )}
          <Button
            color={ButtonColor.WHITE}
            onClick={() => {
              setIsLoading(true);
              window?.scrollTo(0, 0);
              router.push(initialFilterData.data.SEF_DEL_FILTER_URL);
            }}
          >
            {locale.LISTING_CLEAR}
          </Button>
        </div>
      )}
    </div>
  );
};
