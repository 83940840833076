import { useEffect, useState } from 'react';
import { getCurrency } from '@/app/currency';
import { Card } from '@entities/card';
import { useNotesListMutation } from '@shared/api';
import { ENV } from '@shared/config';
import type { Locale } from '@shared/libs';
import type { Notes } from '@shared/types';
import { Pagination, ShowMore } from '@shared/ui';

import styles from '../../listing.module.css';

type TProps = {
  locale: Locale;
  page?: number;
  smart_url?: string;
  SORT?: string;
  data: {
    data: Notes[];
    pager: {
      page_current: number;
      pages_all: number;
      records_all: number;
      records_per_page: number;
    };
  };
  yaCategory?: string;
  h1?: string;
};

export const Result = ({ data, locale, page, smart_url, SORT, yaCategory, h1 }: TProps) => {
  const {
    pager: { pages_all },
  } = data;

  const [localPage, setLocalPage] = useState(page || 1);
  const [moreResultCount, setMoreResultCount] = useState(
    data.pager.records_all - (page || 1) * data.pager.records_per_page,
  );
  useEffect(() => {
    setLocalPage(page || 1);
  }, [page]);

  const [localData, setLocalData] = useState(data.data);
  useEffect(() => {
    setLocalData(data.data);
  }, [data.data]);

  useEffect(() => {
    void (async () => {
      // eslint-disable-next-line @typescript-eslint/await-thenable
      const currency = await getCurrency();
      const prev =
        data.pager.page_current > 1
          ? (data.pager.page_current - 1) * data.pager.records_per_page
          : 0;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      if (window.dataLayer && data?.data && data.pager) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        window.dataLayer.push({
          ecommerce: {
            currencyCode: currency?.value || ENV.DEFAULT_CERRENCY,
            impressions: data?.data.map(
              ({ id, title, musicians, arrangement, instruments }, index) => ({
                id: id.toString(),
                name: `${musicians[0]?.title} - ${title} - ${arrangement?.title}`,
                category: instruments?.[0]?.title,
                brand: arrangement?.title,
                position: prev + index + 1,
                list: h1,
              }),
            ),
          },
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [h1, yaCategory, page]);

  const moreMutation = useNotesListMutation();

  const handleShowMore = async () => {
    const newPage = localPage + 1;
    const { data: newData } = await moreMutation.mutateAsync({
      smart_url,
      page: newPage,
      order: SORT,
    });

    const prevIndex = (newPage - 1) * data.pager.records_per_page;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (window.dataLayer && newData) {
      // eslint-disable-next-line @typescript-eslint/await-thenable
      const currency = await getCurrency();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      window.dataLayer.push({
        ecommerce: {
          currencyCode: currency?.value || ENV.DEFAULT_CERRENCY,
          impressions: newData.map(({ id, title, musicians, arrangement, instruments }, index) => ({
            id: id.toString(),
            name: `${musicians[0]?.title} - ${title} - ${arrangement?.title}`,
            position: prevIndex + index + 1,
            category: instruments?.[0]?.title,
            brand: arrangement?.title,
            list: h1,
          })),
        },
      });
    }

    setLocalPage(newPage);
    setLocalData((prev) => [...prev, ...newData]);
  };

  useEffect(() => {
    setLocalPage(page || 1);
  }, [SORT, page]);

  useEffect(() => {
    const pager = moreMutation.data?.pager || { ...data.pager, page_current: localPage };
    if (pager) {
      const { records_all, records_per_page } = pager;
      setMoreResultCount(records_all - localPage * records_per_page);
    } else {
      setMoreResultCount(0);
    }
  }, [data.pager, moreMutation.data?.pager, localPage]);

  return (
    <>
      <div className={styles.results}>
        {localData.map((item) => (
          <Card.GridNote key={item.id} altText={locale.NOTE_IMG_ALT} {...item} />
        ))}
        {localData.length === 0 && <p className={styles.noData}>{locale.LISTING_NO_RESULT}</p>}
      </div>
      <ShowMore
        moreResultCount={moreResultCount}
        isLoading={moreMutation.isLoading}
        text={locale.SHOW_MORE || 'SHOW_MORE'}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        handleClick={handleShowMore}
      />
      <Pagination
        locale={locale}
        totalPages={pages_all}
        currentPage={localPage}
        query="PAGEN_1"
        queryTail={SORT ? `SORT=${SORT}` : undefined}
      />
    </>
  );
};
