import cn from 'classnames';
import queryString from 'query-string';
import { useCallback } from 'react';

import { getCurrency } from '@/app/currency';

import { ENV } from '@shared/config';
import type { Locale } from '@shared/libs';
import { stylesButton } from '@shared/ui';

import styles from './btn-link.module.css';

type Variants = {
  BASE?: string;
  MIDI?: string;
};

type TProps = {
  variants: Variants | null | [];
  orderId: number;
  noteId: number;
  // eslint-disable-next-line react/no-unused-prop-types
  title: string;
  titleYa?: string;
  category?: string;
  brand?: string;
  token?: string;
  locale: Locale;
  orderStatus?: string;
  status?: string;
};

// Принят, ожидается оплата - order status_id = N
// Готовые ноты: note status=done
// Не готовые и не экспресс: note status=work
// Не готовые и экспресс: note status=express
//
// Предзаказ - order status_id = PO
// Не готовые и не экспресс: note status=work
//
// Оплачен, готов частично- order status_id = ????
// Готовые ноты: note status=done & note variants != null.
// Не готовые и не экспресс: note status=work
// Не готовые и экспресс: note status=express

// Принят, ожидается оплата
// Готовые ноты: Ноты можно будет скачать сразу после оплаты
// Не готовые и не экспресс: После оплаты сделаем в течение 14 рабочих дней.
// Не готовые и экспресс: После оплаты сделаем в течение 3 рабочих дней
//
// Предзаказ
// Не готовые и не экспресс: Мы оповестим вас о том, когда мы сделаем эти ноты.
// Других нот тут просто по определению быть не может, иначе это будет другой статус у заказа.
//
// Оплачен, готов частично
// Готовые ноты: Кнопки для скачивания.
// Не готовые и не экспресс: Ноты будут готовы [от даты оплаты +14 рабочих дней].
// Не готовые и экспресс: Ноты будут готовы [от даты оплаты +3 рабочих дня].

export const LoadNoteBtnLink = ({
  variants,
  orderId,
  noteId,
  token,
  locale,
  status,
  orderStatus,
  titleYa,
  category,
  brand,
}: TProps) => {
  const metrikaSend = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/await-thenable
    const currency = await getCurrency();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    window.dataLayer.push({
      ecommerce: {
        currencyCode: currency?.value || ENV.DEFAULT_CERRENCY,
        purchase: {
          actionField: {
            id: orderId,
          },
          products: [
            {
              id: noteId.toFixed(),
              name: titleYa,
              category,
              brand,
              variant: variants && 'MIDI' in variants ? 'MIDI' : 'BASE',
              quantity: 1,
              position: 1,
            },
          ],
        },
      },
    });
  }, [orderId, noteId, titleYa, category, brand, variants]);

  if (variants && !Array.isArray(variants)) {
    return (
      <div className={styles.wrap}>
        {'MIDI' in variants && (
          <a
            href={`${ENV.API}/order_download/${orderId}/?${queryString.stringify({
              token,
              offer_id: noteId,
              format: 'MIDI',
            })}`}
            download
            onClick={() => void metrikaSend()}
            className={cn(stylesButton.button, stylesButton.s, stylesButton.green, styles.btnLink)}
          >
            {locale.ORDER_DOWNLOAD || 'ORDER_DOWNLOAD'} .midi
          </a>
        )}
        {'BASE' in variants && (
          <a
            href={`${ENV.API}/order_download/${orderId}/?${queryString.stringify({
              token,
              offer_id: noteId,
            })}`}
            download
            onClick={() => void metrikaSend()}
            className={cn(stylesButton.button, stylesButton.s, stylesButton.green, styles.btnLink)}
          >
            {locale.ORDER_DOWNLOAD || 'ORDER_DOWNLOAD'} .pdf
          </a>
        )}
      </div>
    );
  }

  if (orderStatus === 'N') {
    if (status === 'done') {
      return <p className={styles.text}>{locale.ORDER_READY_NO_PAY || 'ORDER_READY_NO_PAY'}</p>;
    }
    if (status === 'work') {
      return (
        <p className={styles.text}>{locale.ORDER_NO_READY_NO_PAY || 'ORDER_NO_READY_NO_PAY'}</p>
      );
    }
    if (status === 'express') {
      return (
        <p className={styles.text}>
          {locale.ORDER_NO_READY_NO_PAY_EXPRESS || 'ORDER_NO_READY_NO_PAY_EXPRESS'}
        </p>
      );
    }
  }

  if (orderStatus === 'PO') {
    return <p className={styles.text}>{locale.ORDER_NO_READY || 'ORDER_NO_READY'}</p>;
  }

  if (orderStatus === 'OP') {
    if (status === 'work') {
      return <p className={styles.text}>{locale.ORDER_NO_READY_PAY || 'ORDER_NO_READY_PAY'}</p>;
    }
    if (status === 'express') {
      return (
        <p className={styles.text}>
          {locale.ORDER_NO_READY_PAY_EXPRESS || 'ORDER_NO_READY_PAY_EXPRESS'}
        </p>
      );
    }
  }

  return null;
};
