import cn from 'classnames';
import Image from 'next/image';
import type { Dispatch, SetStateAction } from 'react';

import { useState } from 'react';
import type { FilterItem } from '@shared/api';
import { chevronDownIcon, chevronUpIcon } from '@shared/assets';
import type { Locale } from '@shared/libs';
import { H4, Button, ButtonColor } from '@shared/ui';

import styles from '../../../listing.module.css';
import { CheckboxList } from './checkbox-list';

type TProps = {
  title: string;
  id: string;
  data: FilterItem['VALUES'];
  locale: Locale;
  checkboxData: string[];
  setCheckboxData: Dispatch<SetStateAction<string[]>>;
};

export const MobileGroup = ({ title, ...checkboxProps }: TProps) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.filtersBlockMobile}>
      <div className={styles.titleMobileBlock}>
        <H4
          unboundedFont={false}
          className={cn(styles.filtersBlockTitle, styles.titleMobile, {
            [styles.titleMobileOpen]: open,
          })}
        >
          {title}
        </H4>

        <Button
          color={ButtonColor.WHITE}
          onClick={() => {
            setOpen((prev) => !prev);
          }}
          className={styles.titleMobileBlockToggle}
        >
          <Image src={!open ? chevronDownIcon : chevronUpIcon} width={24} height={24} alt="close" />
        </Button>
      </div>
      {open && <CheckboxList {...checkboxProps} isMobile />}
    </div>
  );
};
