import cn from 'classnames';
import he from 'he';

import { ENV } from '@shared/config';
import { stylesButton } from '@shared/ui';

import styles from '../../card.module.css';
import { Preview, Instruments, stylesMusicians } from '../../components';
import widgetStyles from './widget.module.css';

interface Props {
  title: string;
  url: string;
  btnText: string;
  arrangement?: {
    id: string;
    title: string;
  };
  img: {
    src: string;
  };
  musicians: {
    title: string;
  }[];
  variant: 'horisontal' | 'vertical';
}

export const Widget = ({
  url: pathname,
  img,
  title,
  musicians,
  arrangement,
  btnText,
  variant,
}: Props) => {
  const url = `${ENV.STORAGE}${pathname}`;

  return (
    <div
      className={cn(widgetStyles.wrap, {
        [widgetStyles.vertical]: variant === 'vertical',
      })}
    >
      <Preview
        className={widgetStyles.preview}
        href={url}
        target="_blank"
        src={img.src}
        alt={title}
      />
      <div className={cn(widgetStyles.text, styles.contentHorisontal)}>
        <div className={cn(widgetStyles.card, styles.contentTop)}>
          <div className={cn(stylesMusicians.musicians, widgetStyles.musicians)}>
            <a href={url} target="_blank">
              {musicians.map((musician) => musician.title).join(', ')}
            </a>
          </div>
          <div className={widgetStyles.title}>
            <a href={url} className={styles.title} target="_blank">
              {he.decode(title)}
            </a>
          </div>
          {arrangement && (
            <Instruments instruments={[{ code: arrangement.id, title: arrangement.title }]} />
          )}

          <a
            href={url}
            target="_blank"
            className={cn(stylesButton.button, stylesButton.black, widgetStyles.btn)}
          >
            {btnText}
          </a>
        </div>
      </div>
    </div>
  );
};
