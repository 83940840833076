import cn from 'classnames';
import { useRouter, usePathname } from 'next/navigation';
import { useEffect, useState, useMemo, useRef } from 'react';

import { useSmartSearchMutattion } from '@shared/api';
import type { FilterResponse } from '@shared/api';
import { ENV } from '@shared/config';
import type { Locale } from '@shared/libs';
import { H4, Toggle, Button, ButtonColor } from '@shared/ui';

import styles from '../../listing.module.css';
import { MobileGroup } from './components';

type TProps = {
  locale: Locale;
  filterData: FilterResponse;
  setIsLoading: (value: boolean) => void;
  isLoading: boolean;
  onClose?: () => void;
};

export const FilterMobile = ({
  locale,
  filterData: initialFilterData,
  setIsLoading,
  isLoading,
  onClose,
}: TProps) => {
  const [pathLink, setPathLink] = useState<undefined | string>(undefined);
  // Для стейта (все выбранные чекбоксы при инициализации страницы)
  const smartSearchMutattion = useSmartSearchMutattion();

  const initialCheckboxData = useMemo(() => {
    const result: string[] = [];
    Object.entries(initialFilterData.data.ITEMS).forEach(([id, { VALUES }]) => {
      if (Number(id) !== ENV.FILTER_ID_IS_FREE) {
        Object.values(VALUES).forEach(({ CHECKED, CONTROL_NAME }) => {
          if (CHECKED) {
            result.push(CONTROL_NAME);
          }
        });
      }
    });

    return result;
  }, [initialFilterData]);
  const [checkboxData, setCheckboxData] = useState<string[]>(initialCheckboxData);
  const [filterData, setFilterData] = useState<FilterResponse>(initialFilterData);

  // отдельно обрабатывается сложность - т.к. это toggle
  const [isFree, setIsFree] = useState(
    initialFilterData.data.ITEMS[ENV.FILTER_ID_IS_FREE as 63].VALUES[ENV.FILTER_IS_FREE_VALUE]
      ?.CHECKED || false,
  );
  const router = useRouter();
  const pathname = usePathname();

  const firstRender = useRef(true);

  useEffect(() => {
    if (smartSearchMutattion.data) {
      // @ts-expect-error type
      setFilterData(smartSearchMutattion.data);
    }
  }, [smartSearchMutattion.data]);

  useEffect(() => {
    if (smartSearchMutattion.data) {
      const response = smartSearchMutattion.data as FilterResponse;

      firstRender.current = true;
      const path = response.data.SEF_SET_FILTER_URL;
      setCheckboxData(() => {
        const result: string[] = [];
        Object.entries(response.data.ITEMS).forEach(([id, { VALUES }]) => {
          if (Number(id) !== ENV.FILTER_ID_IS_FREE) {
            Object.values(VALUES).forEach(({ CHECKED, CONTROL_NAME }) => {
              if (CHECKED) {
                result.push(CONTROL_NAME);
              }
            });
          }
        });

        return result;
      });

      firstRender.current = true;

      firstRender.current = true;
      setIsFree(
        response.data.ITEMS[ENV.FILTER_ID_IS_FREE as 63].VALUES[ENV.FILTER_IS_FREE_VALUE]
          ?.CHECKED || false,
      );
      setPathLink(path);
      // router.push(path, { scroll: false });
    }
  }, [smartSearchMutattion.data, router]);

  const { mutate } = smartSearchMutattion;

  const freeControlName =
    initialFilterData?.data?.ITEMS[ENV.FILTER_ID_IS_FREE as 63].VALUES[ENV.FILTER_IS_FREE_VALUE]
      ?.CONTROL_NAME;

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      let filters: string[] = [];

      if (checkboxData.length) {
        filters = [...checkboxData];
      }

      if (isFree && freeControlName) {
        filters.push(freeControlName);
      }

      mutate({ filters });
    }
  }, [checkboxData, isFree, mutate, freeControlName]);

  const showActions =
    pathLink &&
    pathLink !== pathname &&
    !(pathLink === '/category/clear/apply/' && pathname === '/');

  return (
    <div
      className={cn(styles.filters, styles.filtersMobile, {
        [styles.isLoading]: smartSearchMutattion.isLoading || isLoading,
      })}
    >
      <div>
        <div className={cn(styles.filtersBlockMobile, styles.filtersBlockMobileIsFree)}>
          <div className={styles.filtersBlockOneLineTitle}>
            <H4 unboundedFont={false} className={styles.titleMobile}>
              {filterData?.data.ITEMS[ENV.FILTER_ID_IS_FREE as 63].NAME}
            </H4>
            <Toggle
              toggleName={`${freeControlName}_mobile`}
              checked={isFree}
              onClick={() => {
                setIsFree((prev) => !prev);
              }}
              disabled={
                Number(
                  filterData?.data.ITEMS[ENV.FILTER_ID_IS_FREE as 63].VALUES[
                    ENV.FILTER_IS_FREE_VALUE
                  ].ELEMENT_COUNT,
                ) === 0
              }
            />
          </div>
        </div>
        <MobileGroup
          title={filterData?.data.ITEMS[ENV.FILTER_ID_INSTRUMENTS as 176].NAME || ''}
          id="FILTER_ID_INSTRUMENTS"
          data={filterData?.data.ITEMS[ENV.FILTER_ID_INSTRUMENTS as 176].VALUES || {}}
          locale={locale}
          checkboxData={checkboxData}
          setCheckboxData={setCheckboxData}
        />
        <MobileGroup
          title={filterData?.data.ITEMS[ENV.FILTER_ID_COMPLEXITY as 183].NAME || ''}
          id="FILTER_ID_COMPLEXITY"
          data={filterData?.data.ITEMS[ENV.FILTER_ID_COMPLEXITY as 183].VALUES || {}}
          locale={locale}
          checkboxData={checkboxData}
          setCheckboxData={setCheckboxData}
        />
        <MobileGroup
          title={filterData?.data.ITEMS[ENV.FILTER_ID_ARRANGEMENT as 50].NAME || ''}
          id="FILTER_ID_ARRANGEMENT"
          data={filterData?.data.ITEMS[ENV.FILTER_ID_ARRANGEMENT as 50].VALUES || {}}
          locale={locale}
          checkboxData={checkboxData}
          setCheckboxData={setCheckboxData}
        />
        <MobileGroup
          title={filterData?.data.ITEMS[ENV.FILTER_ID_GENRE as 61].NAME || ''}
          id="FILTER_ID_GENRE"
          data={filterData?.data.ITEMS[ENV.FILTER_ID_GENRE as 61].VALUES || {}}
          locale={locale}
          checkboxData={checkboxData}
          setCheckboxData={setCheckboxData}
        />
      </div>

      {(showActions ||
        (pathname !== '/' && pathname !== initialFilterData.data.SEF_DEL_FILTER_URL)) && (
        <div className={styles.actionBlockMobile}>
          <Button
            color={ButtonColor.WHITE}
            onClick={() => {
              setIsLoading(true);
              window?.scrollTo(0, 0);
              router.push(initialFilterData.data.SEF_DEL_FILTER_URL);
              onClose?.();
            }}
          >
            {locale.LISTING_CLEAR}
          </Button>
          {showActions && (
            <Button
              onClick={() => {
                setIsLoading(true);
                window?.scrollTo(0, 0);
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                router.push(pathLink!);
                setPathLink(undefined);
                onClose?.();
              }}
            >
              {locale.LISTING_SEARCH}
              {smartSearchMutattion.data?.data.ELEMENT_COUNT
                ? ` (${Number(smartSearchMutattion.data?.data.ELEMENT_COUNT).toLocaleString()})`
                : ''}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
