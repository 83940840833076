import cn from 'classnames';
import he from 'he';
import Link from 'next/link';
import type { ReactNode } from 'react';

import { Complexity } from '@shared/ui';

import styles from '../../card.module.css';
import { Preview, BtnLink, Instruments, Musicians } from '../../components';

type Variants = {
  BASE?: string;
  MIDI?: string;
};

interface Props {
  id: number;
  orderId: number;
  title: string;
  status: string;
  url: string;
  arrangement: {
    code: string;
    title: string;
  };
  instruments: {
    code: string;
    title: string;
  }[];
  img: {
    src: string;
    src_256_362: string;
  };
  complexity: {
    level: 10 | 20 | 30;
    title: string;
  } | null;
  musicians: {
    title: string;
  }[];
  variants: Variants | [];
  Variants: (props: {
    variants: Variants | [];
    noteId: number;
    orderId: number;
    title: string;
    titleYa?: string;
    category?: string;
    brand?: string;
    status?: string;
  }) => ReactNode;
}

export const OrderNote = ({
  id,
  orderId,
  url,
  status,
  img,
  title,
  musicians,
  arrangement,
  variants,
  complexity,
  Variants,
  instruments,
}: Props) => {
  return (
    <div className={cn(styles.cardHorisontal, styles.cardBeige)}>
      <Complexity complexity={complexity} className={styles.complexityAbsolute} />
      <Preview
        href={url}
        src={img.src_256_362 || img.src}
        alt={title}
        contrast
        className={styles.preview}
      />

      <div className={styles.contentHorisontal}>
        <div className={styles.contentTop}>
          <Musicians musicians={musicians} url={url} />
          <Link href={url} className={styles.title}>
            {he.decode(title)}
          </Link>
          <Instruments instruments={[arrangement]} />
        </div>
        <div className={styles.variants}>
          <Variants
            noteId={id}
            orderId={orderId}
            variants={variants}
            title={title}
            status={status}
            titleYa={`${musicians[0]?.title} - ${title} - ${arrangement.title}`}
            category={instruments?.[0]?.title}
            brand={arrangement.title}
          />
        </div>
      </div>
      <BtnLink href={url} />
    </div>
  );
};
