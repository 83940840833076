import cn from 'classnames';
import he from 'he';
import Link from 'next/link';

// eslint-disable-next-line boundaries/element-types
import { LoadNoteBtnLink } from '@features/load-note';

import type { Locale } from '@shared/libs';
import { Complexity } from '@shared/ui';

import styles from '../../card.module.css';
import { Preview, BtnLink, Instruments, stylesMusicians } from '../../components';

interface Props {
  id: number;
  title: string;
  url: string;
  order_id: number;
  arrangement: {
    code: string;
    title: string;
  };
  img: {
    src: string;
    src_256_362: string;
  };
  complexity: {
    level: 10 | 20 | 30;
    title: string;
  } | null;
  musicians: {
    title: string;
  }[];
  variants:
    | {
        BASE?: string;
        MIDI?: string;
      }
    | null
    | [];
  locale: Locale;
  token?: string;
}

export const MyNote = ({
  id,
  url,
  img,
  title,
  musicians,
  arrangement,
  variants,
  complexity,
  order_id,
  locale,
  token,
}: Props) => {
  return (
    <div className={cn(styles.cardHorisontal, styles.cardWhite)}>
      <Complexity complexity={complexity} className={styles.complexityAbsolute} />
      <Preview
        href={url}
        target="_blank"
        src={img.src_256_362 || img.src}
        alt={title}
        className={styles.previewWrap}
      />

      <div className={styles.contentHorisontal}>
        <div className={styles.contentTop}>
          <div className={stylesMusicians.musicians}>
            <Link href={url} target="_blank" prefetch={false}>
              {musicians.map((musician) => musician.title).join(', ')}
            </Link>
          </div>
          <div>
            <Link href={url} className={styles.title} target="_blank" prefetch={false}>
              {he.decode(title)}
            </Link>
          </div>
          <Instruments instruments={[arrangement]} />
        </div>
        <LoadNoteBtnLink
          variants={variants}
          orderId={order_id}
          noteId={id}
          token={token}
          locale={locale}
          title=""
        />
      </div>
      <BtnLink href={url} />
    </div>
  );
};
