import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import useLocalStorage from 'use-local-storage';

import type { FilterItem } from '@shared/api';
import type { Locale } from '@shared/libs';
import { Checkbox } from '@shared/ui';

import styles from '../../../listing.module.css';
import stylesList from './list.module.css';

type Props = {
  id: string;
  data: FilterItem['VALUES'];
  locale: Locale;
  checkboxData: string[];
  setCheckboxData: Dispatch<SetStateAction<string[]>>;
  isMobile?: boolean;
  setLastChangeElement?: Dispatch<SetStateAction<HTMLInputElement | null>>;
};

export const CheckboxList = ({
  id,
  data,
  locale,
  checkboxData,
  setCheckboxData,
  isMobile,
  setLastChangeElement,
}: Props) => {
  const sortedData = useMemo(() => {
    const arr = Object.values(data);
    arr.sort((a, b) => (a.SORT > b.SORT ? 1 : -1));
    return arr;
  }, [data]);

  const [showLsAll, setShowLsAll] = useLocalStorage(id, false);
  const [showLocalAll, setShowLocalAll] = useState(false);
  useEffect(() => {
    const hasCheck = Object.values(data).some(({ CHECKED }) => CHECKED);
    if (hasCheck) {
      setShowLocalAll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line no-constant-condition
  const showAll = true ? showLocalAll : showLsAll;
  // eslint-disable-next-line no-constant-condition
  const setShowAll = true ? setShowLocalAll : setShowLsAll;

  const visibleData = useMemo(() => {
    if (sortedData.length <= 3 || showAll) {
      return sortedData;
    }

    return [...sortedData].slice(0, 3);
  }, [sortedData, showAll]);

  return (
    <div className={styles.list}>
      {visibleData.map(({ VALUE, CONTROL_NAME, CHECKED, ELEMENT_COUNT }) => (
        <div key={CONTROL_NAME} className={styles.filterOption}>
          <Checkbox
            name={`${isMobile && 'mobile_'}${VALUE}_${CONTROL_NAME}`}
            onChange={(e) => {
              if (setLastChangeElement) {
                setLastChangeElement(null);
                setTimeout(() => {
                  setLastChangeElement(e.target);
                }, 200);
              }
              if (e.target.checked) {
                setCheckboxData((prev) => [...prev, CONTROL_NAME]);
              } else {
                setCheckboxData((prev) => prev.filter((item) => item !== CONTROL_NAME));
              }
            }}
            value={CONTROL_NAME}
            disabled={Number(ELEMENT_COUNT) === 0}
            checked={
              Number(ELEMENT_COUNT) === 0
                ? false
                : CHECKED || Boolean(checkboxData.find((item) => item === CONTROL_NAME))
            }
          >
            <span className={cn(stylesList.item, stylesList.itemMobile)}>
              {VALUE}
              {Number(ELEMENT_COUNT) > 0 && (
                <span className={stylesList.count}>
                  {new Intl.NumberFormat('ru-RU').format(Number(ELEMENT_COUNT))}
                </span>
              )}
            </span>
          </Checkbox>
        </div>
      ))}
      {sortedData.length > 3 && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <p
          className={cn(stylesList.more, {
            [stylesList.open]: showAll,
          })}
          onClick={() => {
            setShowAll((prev) => !prev);
          }}
        >
          {showAll ? locale.MENU_TOGGLE_CLOSE : locale.MENU_TOGGLE_OPEN}
        </p>
      )}
    </div>
  );
};
