import { FavoriteNote, MyNote, BasketNote, OrderNote, GridNote, Widget } from './variants';

export const Card = {
  FavoriteNote,
  MyNote,
  BasketNote,
  OrderNote,
  GridNote,
  Widget,
};
